<script>
  import Post from '@/views/operate/customAdvertising/compenents/postCommon.vue'
  import { getAllClassificationPage } from '@/api/operate'
  import { getList } from '@/api/router'
  import config from '../../config'
  export default {
    components: {
      Post,
    },
    data() {
      return {
        ...config,
        params: {
          pic: null,
          title: null,
          begin_time: null,
          end_time: null,
          link: null,
          place: null,
          position: 1,
          tag: null,
          is_show: 1,
          upload_type: 1,
          pic_url: null,
          ac_op: -1,
          show_label: -1,
          is_can_close: -1,
          show_title: null,
          class_id: null,
          audit_config: [],
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '是否显示',
            prop: 'is_show',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '广告备注',
            prop: 'title',
          },
          {
            label: '审核配置',
            prop: 'ac_op',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '所属栏目',
            prop: 'place',
            type: 'select',
            list: this.columnTypeList,
          },
          {
            label: '配置项',
            prop: 'audit_config',
            type: 'checkbox',
            list: this.phoneConfigList,
            tips: '若选择审核版，则用户看不到配置的内容，若选择普通版，则审核人员看不到配置的内容',
            isHide: this.params.ac_op != 1,
          },
          {
            label: '显示标题',
            prop: 'show_title',
          },
          {
            label: '上传图片',
            prop: 'pic',
            type: 'uploadImage',
            width: 360,
            height: 150,
            eventName: 'handleImageUrl',
            tips: '建议尺寸760x210px',
          },
          {
            label: '跳转',
            prop: 'link',
            tips: '选填，可以配置app内部任意页面的跳转链接。',
            // clickText: '选择跳转地址',
            // clickEventName: 'handleCheckLink',
          },
          {
            label: '位置',
            prop: 'position',
            type: 'number',
            tips: '广告中心列表中显示的固定位置，数字相同时将随机显示',
          },
          {
            label: '是否显示广告标签',
            prop: 'show_label',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '是否允许关闭',
            prop: 'is_can_close',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '广告分类',
            prop: 'class_id',
            type: 'select',
            optionLabel: 'name',
            list: this.advertisingTypeList,
          },
          {
            label: '开始时间',
            prop: 'begin_time',
            timeType: 'datetime',
            type: 'startDaterangePicker',
          },
          {
            label: '结束时间',
            prop: 'end_time',
            timeType: 'datetime',
            type: 'endDaterangePicker',
          },
        ]
      },
    },
    created() {
      this.fetchData()
      this.getAllClassificationPage()
    },
    methods: {
      getAllClassificationPage() {
        getAllClassificationPage({}).then((res) => {
          this.advertisingTypeList = res.list || []
        })
      },
      // 所属栏目
      async fetchData() {
        const params = {
          type: 3,
        }
        const res = await getList(params)
        if (res.success) {
          this.columnTypeList = [
            {
              label: '请选择',
              id: 'portal_bottom_all',
            },
          ]
          res.list = res.list.map((item) => {
            return {
              label: item.name,
              id: `portal_bottom_all${item.id}`,
            }
          })
          this.columnTypeList = this.columnTypeList.concat(res.list)
        }
      },
    },
  }
</script>
